<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/grichkarip.jpg')"
          class="my-3"
          contain
          height="400"
        />
      </v-col>

      <v-col class="mb-4">
        <h1>
            In Memoriam
        </h1>
        <h1 class="display-2 font-weight-bold mb-1">
          Grichka Yourievitch Bogdanoff
        </h1>
        <h1 class="mb-3">
            (1949-2021)
        </h1>

        <p class="subheading font-weight-regular">
            Grichka Yourievitch Bogdanoff, 72, passed on December 28, 2021.
            <br/>He was born to Yuri Mikhaïlovitch Osten-Sacken-Bogdanoff and
            <br/> Maria Dolores Franzyska Kolowrat-Krakowská on August 29, 1949.
        </p>

        <p class="subheading font-weight-regular">
          To commemorate the passing of this beloved cultural icon,
          <br>please join our online
          <a
            href="https://t.me/bogdanoffrip"
            target="_blank"
          >Telegram Community</a>
        </p>

        <p class="subheading font-weight-regular mb-10">
          A remembrance token has been launched on Binance Smart Chain.
          <br>The proceeds raised from the reflections will be put towards a fitting cause<br/>
            We hope to immortalize the lasting impact that Grichka had on so many of our lives.
        </p>

        <h2>
            Tokenomics
        </h2>
        <p class="subheading font-weight-regular">
            All transactions subject to 9% tax.<br/>
            Set slippage to 10%.
        </p>
        <p>
            - 2% Remebrance fund<br/>
            - 5% LP reflection<br/>
            - 2% <a href="https://www.coingecko.com/en/coins/bogged-finance">$BOG</a> rewards<br/>
        </p>


      </v-col>

      <!-- <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          What's next?
        </h2>

        <v-row justify="center">
          <a
            v-for="(next, i) in whatsNext"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ next.text }}
          </a>
        </v-row>
      </v-col> -->

      <v-col
        class="d-flex justify-center"
        cols="12"
      >
        <iframe
            id="rubic-widget-iframe"
            height="500"
            width="350"
            style="margin-bottom: 40px; border: none; border-radius: 19px; box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 10px 4px; display: block; opacity: 1; visibility: visible;"
            src="https://app.rubic.exchange/?iframe=vertical&amp;amount=10000000000&amp;background=%23FEF6E0&amp;bsc_tokens=%5B%220xb09d489fccded91292df2332c9af7892a0249383%22%5D&amp;device=desktop&amp;from=0xb09d489fccded91292df2332c9af7892a0249383&amp;fromChain=BSC&amp;hideSelectionFrom=false&amp;hideSelectionTo=true&amp;theme=light&amp;to=BNB&amp;toChain=BSC" onload="onFrameLoad()">
        </iframe>
      </v-col>

      <v-col
        class="mb-10"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-5">
          Important Links
        </h2>

        <v-row justify="center">
          <a
            v-for="(link, i) in importantLinks"
            :key="i"
            :href="link.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </v-row>
      </v-col>

      <!-- <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Ecosystem
        </h2>

        <v-row justify="center">
          <a
            v-for="(eco, i) in ecosystem"
            :key="i"
            :href="eco.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ eco.text }}
          </a>
        </v-row>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'RipGrichka',

    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Token',
          href: 'https://bscscan.com/address/0xb09d489FccdED91292DF2332C9aF7892A0249383#code',
        },
        {
          text: 'Timelock',
          href: 'https://dxsale.app/app/v3/dxlockview?id=0&add=0x893556157e834bFD21aad0A14905B6d4db9011af&type=lplock&chain=BSC',
        },
        {
          text: 'DexScreener',
          href: 'https://dexscreener.com/bsc/0x1337d4c02bc78d3f255cc962459d470d8d3c8781',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/ripbogdanoff',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
  }
</script>
